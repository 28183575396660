<template>
	<div class="container">
		<div class="flex-row align-center justify-center nav">
			<div class="nav-title-bg"></div>
			<div class="nav-title">红包卡券</div>
		</div>
		<van-list v-model="loading" @load="getUserCommission" :finished="finished" :finished-text="msg"
			:error.sync="error" error-text="请求失败，点击重新加载">
			<template v-for="(item,index) in list">
				<div class="item flex-row align-center" v-if="item.status ==1 || item.status ==2" :key="index">
					<div class="price-wrap flex-row align-end  justify-center">
						<div class="price " :class="item.amount>999?'price2':''">{{item.amount}}</div>
						<div class="unit unit2" :class="item.amount>999?'price2':''">元</div>
					</div>
					<div class="c-wrap flex-column">
						<div class="limit">满{{item.limitAmount}}元可用</div>
						<div class="date">截止日期：{{item.formatDate}}</div>
					</div>
					<div class="status flex-column space-around" v-if="item.status == 1">
						<div class="text">已</div>
						<div class="text">使</div>
						<div class="text">用</div>
					</div>
					<div class="status flex-column space-around" v-else>
						<div class="text">已</div>
						<div class="text">过</div>
						<div class="text">期</div>
					</div>
				</div>
				<!-- <div class="item2 flex-row align-center">
					<div class="price-wrap flex-row align-end  justify-center">
						<div class="price">100</div>
						<div class="unit">元</div>
					</div>
					<div class="c-wrap flex-column">
						<div class="limit">满200</div>
						<div class="date">截止日期：2022</div>
					</div>
					<div class="status flex-column space-around">
						<div class="text">使 </div>
						<div class="text">用</div>
						<div class="text">中</div>
					</div>
				</div> -->
				<div class="item3 flex-row align-center" v-if="item.status ==0" :key="index">
					<div class="price-wrap flex-row align-end  justify-center">
						<div class="price" :class="item.amount>999?'price2':''">{{item.amount}}</div>
						<div class="unit" :class="item.amount>999?'price2':''">元</div>
					</div>
					<div class="c-wrap flex-column">
						<div class="limit">满{{item.limitAmount}}元可用</div>
						<div class="date">截止日期：{{item.formatDate}}</div>
					</div>
					<div class="status flex-column space-around" @click.stop="navMember()">
						<div class="text">立 </div>
						<div class="text">即</div>
						<div class="text">使</div>
						<div class="text">用</div>
					</div>
				</div>
			</template>
		</van-list>
		<div class="empty-text" v-if="list.length == 0 && request">没有红包卡券</div>
	</div>
</template>

<script>
	import wx from 'weixin-js-sdk'
	import config from '../../config/config.js';
	import util from '../../config/util.js';
	import {
		Toast,
		Dialog
	} from 'vant';
	import 'vant/lib/toast/index.css';
	import 'vant/lib/dialog/index.css';
	export default {
		data() {
			return {
				loading: false,
				finished: false,
				error: false,
				pageNum: 1,
				pageSize: 10,
				total: '',
				list: [],
				request: 0,
				msg:''
			}
		},
		methods: {
			navMember(){
				this.$router.replace('/collect')
			},
			getUserCommission() {
				const that = this
				let params = new FormData()
				that.axios.post(config.pointsRequest +
					`/point-api/rest/pointUserCommission/getUserCommission?pageNum=${that.pageNum}&pageSize=${that.pageSize}`,
					'0', {
						headers: {
							'Content-Type': 'application/json',
							'token': that.$store.state.user.token
						}
					}).then(response => {
					if (response.data.code == 0) {
						that.request = 1;
						that.pageNum = that.pageNum + 1;
						let list = that.list;
						let newList = response.data.data;
						for (let i in newList) {
							let dueTime = newList[i].dueTime.replace(/-/g, "/");
							newList[i].formatDate = util.formatDate2(dueTime)
						}
						console.log(newList)
						if (newList.length < that.pageSize) that.finished = true;
						list = list.concat(newList);
						that.list = list;
						that.total = response.data.total
						if(that.request == 1 && that.list.length >0){
							that.msg='没有更多了'
						}
					} else {
						that.error = true
						Toast(response.data.msg);
					}
					that.loading=false;
				}, response => {
					that.error = true
					that.loading=false;
					Toast('获取失败，请稍后重试');
				})
			}
		}
	}
</script>

<style scoped="scoped">
	.item {
		width: 690px;
		height: 165px;
		margin: 20px auto 0;
		background: #FFFFFF;
		background-image: url(../../assets/points/card-status1.png);
		background-size: 690px 165px;
	}

	.price-wrap {
		width: 260px;
	}

	.item .price {
		font-size: 80px;
		color: #808080;

	}

	.item .unit {
		font-size: 26px;
		font-weight: 400;
		margin-left: 5px;
		color: #808080;
		line-height: 55px;
	}

	.item .c-wrap {
		margin-left: 30px;
		width: 340px;
	}

	.item .limit {
		font-size: 28px;
		font-weight: 400;
		color: #808080;
	}

	.item .date {
		font-size: 20px;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.5000);
		margin-top: 17px;
	}

	.status {
		height: 125px;
	}

	.item .text {
		font-size: 26px;
		font-weight: 600;
		color: #808080;
	}

	.item2 {
		width: 690px;
		height: 165px;
		margin: 20px auto 0;
		background: #FFFFFF;
		background-image: url(../../assets/points/card-status3.png);
		background-size: 690px 165px;
	}


	.item2 .price {
		font-size: 80px;
		color: #6236FF;

	}

	.item2 .unit {
		font-size: 26px;
		font-weight: 400;
		margin-left: 5px;
		color: #000000;
		line-height: 55px;
	}

	.item2 .c-wrap {
		margin-left: 30px;
		width: 340px;
	}

	.item2 .limit {
		font-size: 28px;
		font-weight: 400;
		color: #000000;
	}

	.item2 .date {
		font-size: 20px;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.5000);
		margin-top: 17px;
	}


	.item2 .text {
		font-size: 26px;
		font-weight: 600;
		color: #000000;
	}

	.item3 {
		width: 690px;
		height: 165px;
		margin: 20px auto 0;
		background: #FFFFFF;
		background-image: url(../../assets/points/card-status2.png);
		background-size: 690px 165px;
	}


	.item3 .price {
		font-size: 80px;
		color: #E02020;

	}

	.item3 .unit {
		font-size: 26px;
		font-weight: 400;
		margin-left: 5px;
		color: #000000;
		line-height: 55px;
	}

	.item3 .c-wrap {
		margin-left: 30px;
		width: 340px;
	}

	.item3 .limit {
		font-size: 28px;
		font-weight: 400;
		color: #000000;
	}

	.item3 .date {
		font-size: 20px;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.5000);
		margin-top: 17px;
	}


	.item3 .text {
		font-size: 26px;
		font-weight: 600;
		color: #79401A;
	}

	.price2 {
		font-size: 47px !important;
	}

	.unit2 {
		line-height: 40px !important;
	}
	
	.empty-text {
		font-size: 30px;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.5);
		line-height: 30px;
		text-align: center;
		margin-top: 621px;
	}
</style>
